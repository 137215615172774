import React from 'react'

export interface LayoutProps {
  /** The children to be rendered. Each child without `col-span-{number}` will set `1` column as default  */
  children: React.ReactNode
  /** The layout to be used */
  mode?: 'desktop' | 'mobile'
  /** <strong>Use it only for debugging</strong> - Show the grid system  */
  showGrid?: boolean
}

/**
 * It accepts `children` and renders it in a layout.
 * If `mode` is passed and set to `mobile`, grid is used as a 1-column layout, otherwise it is an 11-column layout.
 * @param props { LayoutProps } { `children`, `mode`, `showGrid` }
 * @constructor
 */
export function Layout(props: LayoutProps) {
  const { children, mode, showGrid } = props
  const gridClasses = ['grid gap-x-8', mode === 'mobile' ? 'grid-cols-1' : 'grid-cols-11'].join(' ')
  const columns = mode === 'mobile' ? 1 : 11
  return (
    <div className="relative mx-auto max-w-7xl py-6 px-4">
      <div className={gridClasses}>{children}</div>
      {showGrid && (
        <div className={`absolute top-0 left-0 w-full px-4 ${gridClasses}`}>
          {Array.from({ length: columns }).map((e, i) => (
            <div key={i} className="bg-primary-200 min-h-screen opacity-30" />
          ))}
        </div>
      )}
    </div>
  )
}

Layout.defaultProps = {
  mode: 'mobile',
  showGrid: false,
}

export default Layout
